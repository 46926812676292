/*eslint-disable*/
import React from "react";

export default {
  en: {
    quote:
      "You can go with confidence, they will find a solution suited to your needs and they listen",
    author: "David Martins, GIS Project Manager",
    sector: "Chamber of Commerce and Industry",
    interviewees: [
      "David Martins, GIS Project Manager",
      "Audrey Derouen, Geomatician",
    ],
    service: "Dedicated Map Server, Custom map style",
    geo: "France",
    blocks: [
      {
        title:
          "Chamber of Commerce and Industry Paris Île-de-France (CCI Paris IDF)",
        description: (
          <span>
            The{" "}
            <a
              href="https://www.cci-paris-idf.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Paris Ile-de-France Chamber of Commerce and Industry's
            </a>{" "}
            mission is to support companies in the region in their development
            and represent their interests with the public authorities. David
            Martins is in charge of the Geographical Information System of CCI
            Paris Ile-de-France. He ensures the maintenance, the enrichment and
            the good functioning of the GIS. Audrey Derouen is a geomatician,
            she uses tools and associated data to work on GIS studies.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "CCI Paris IDF & Jawg",
        description:
          "While David Martins had to work on the implementation of a new regional GIS, he had to review the uses and practices of what was used until then. The maps exploited at the time presented several problems: poor aesthetic, lots of storage space required, outdated data.",
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            Looking for an alternative, David Martins contacted Jawg and decided
            to move forward with their solutions. The commercial relationship is{" "}
            <q>professional and honest</q>, but it is above all the flexibility{" "}
            <q>to accommodate at the operational level</q> that has been
            appreciated. Since the start-up phase{" "}
            <q>it has been running smoothly for almost 4 years</q> says David
            Martins.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            At CCI, maps are used to be printed on paper or put in slides.{" "}
            <q>The aesthetic side is of fundamental importance</q>, explains
            David Martins. Jawg was able to work on other specific topics:
            isochrones, deployment of an embedded tile server, etc. What they
            appreciated with each collaboration{" "}
            <q>is to have people in front of you who are reactive</q>.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            What David Martins likes most is{" "}
            <q>
              a little bit of everything, it's the quality of the base map, it
              is the technical competence behind, it is the fact that we can
              count on you
            </q>
            . Audrey Derouen particularly appreciated being able to{" "}
            <q>customize the maps as desired</q> during a co-design workshop
            with a Jawg maps expert.{" "}
            <q>To work like that makes the difference</q>.
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Professional, Responsive, Nice",
      },
    ],
  },
  fr: {
    quote:
      "Vous pouvez y aller tranquillement, ils trouveront une solution adaptée à vos besoins et ils sont à l'écoute",
    author: "David Martins, Responsable Projet SIG",
    sector: "Chambre de commerce et d’industrie",
    interviewees: [
      "David Martins, Responsable Projet SIG",
      "Audrey Derouen, Géomaticienne",
    ],
    service: "Serveur de carte dédié, Style sur mesure",
    geo: "France",
    blocks: [
      {
        title:
          "Chambre de Commerce et d’Industrie Paris Île-de-France (CCI Paris IDF)",
        description: (
          <span>
            La{" "}
            <a
              href="https://www.cci-paris-idf.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              CCI Paris Île-de-France
            </a>{" "}
            a pour mission d’accompagner les entreprises de la région dans leur
            développement et de représenter leurs intérêts auprès des pouvoirs
            publics. David Martins est chargé du Système d’Information
            Géographique de la CCI Paris Ile-de-France. Il assure la
            maintenance, l’enrichissement et le bon fonctionnement du SIG.
            Audrey Derouen est géomaticienne et exploite les outils et les
            données associées pour travailler sur des études SIG.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "CCI Paris IDF & Jawg",
        description:
          "Alors que David Martins devait travailler sur la mise en place d’un nouveau SIG régional, il devait notamment faire le bilan des usages et pratiques de ce qui avait été utilisé jusque-là. Les fonds de plan exploités à l’époque présentaient plusieurs problèmes : esthétique laissant à désirer, espace de stockage nécessaire important, données obsolètes.",
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            En cherchant une alternative, David Martins prend contact avec Jawg
            et y trouve une solution qui s’aligne avec ses contraintes et ses
            besoins. La relation commerciale est{" "}
            <q>&#160;professionnelle et franche&#160;</q>, mais c’est surtout la
            souplesse <q>&#160;au niveau de l’aspect opérationnel&#160;</q> qui
            a été appréciée. Depuis la phase de démarrage{" "}
            <q>
              &#160;ça tourne sans aucun problème depuis bientôt 4 ans&#160;
            </q>{" "}
            témoigne David Martins.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            À la CCI, les cartes sont notamment utilisées pour être imprimées
            sur papier ou être mises dans des diapositives. Le{" "}
            <q>&#160;côté esthétique a une importance fondamentale&#160;</q>{" "}
            explique David Martins. Jawg a pu intervenir sur d’autres sujets
            spécifiques : isochrones, déploiement d’un serveur de tuiles
            embarqué, etc. Ce qu’ils ont apprécié à chaque collaboration{" "}
            <q>
              &#160;c’est d’avoir des gens en face qui soient réactifs&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Ce qui plaît le plus à David Martins, c’est{" "}
            <q>
              &#160;un peu tout, c’est la qualité des fonds de plan, c’est la
              compétence technique derrière, c’est le fait qu’on puisse compter
              sur vous&#160;
            </q>
            . Audrey Derouen, elle, a particulièrement apprécié pouvoir{" "}
            <q>&#160;personnaliser le fond tel que souhaité&#160;</q> lors d’un
            atelier de co-conception avec un expert cartographie de Jawg.{" "}
            <q>&#160;Travailler comme ça, c’est la différence&#160;</q>.
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Professionnel, Réactif, Sympa",
      },
    ],
  },
};
